import { createBrowserRouter, RouterProvider } from "react-router-dom"
import React from "react"
import {
  AccountAddresses,
  AccountDashboard,
  AccountInformation,
  AccountOrders,
  AccountPaymentMethods,
  AccountSubscriptions,
  AccountMemberships,
  Subscription,
  SubscriptionCheckout,
  AccountPaymentMethodEdit,
  AccountPaymentMethodCreate,
  Home,
  NotFound,
} from "pages"
import ProtectedRoutes from "routes/ProtectedRoutes"
import RootWrapper from "routes/RootWrapper"
import PrivacyPolicy from "pages/PrivacyPolicy"
import TermsOfUse from "pages/TermsOfUse"

export const router = createBrowserRouter([
  {
    element: <RootWrapper />,
    children: [
      {
        path: "/account",
        handle: {
          crumb: "My Account",
        },
        element: <ProtectedRoutes />,
        children: [
          {
            element: <AccountDashboard />,
            index: true,
          },
          {
            path: "info",
            element: <AccountInformation />,
            handle: {
              crumb: "Account Information",
            },
          },
          {
            path: "subscriptions",
            element: <AccountSubscriptions />,
            handle: {
              crumb: "Subscriptions",
            },
          },
          {
            path: "memberships",
            element: <AccountMemberships />,
            handle: {
              crumb: "Memberships",
            },
          },
          {
            path: "orders",
            element: <AccountOrders />,
            handle: {
              crumb: "Purchase History",
            },
          },
          {
            path: "payment-methods",
            handle: {
              crumb: "Payment Methods",
            },
            children: [
              {
                element: <AccountPaymentMethods />,
                index: true,
              },
              {
                path: "create",
                element: <AccountPaymentMethodCreate />,
                handle: {
                  crumb: "Create",
                },
              },
              {
                path: "edit",
                element: <AccountPaymentMethodEdit />,
                handle: {
                  crumb: "Edit",
                },
              },
            ],
          },
          {
            path: "addresses",
            element: <AccountAddresses />,
            handle: {
              crumb: "Addresses",
            },
          },
        ],
      },
      {
        path: "/:slug",
        element: <Subscription />,
      },
      {
        path: "/checkout",
        element: <SubscriptionCheckout />,
      },
      {
        path: "/",
        handle: {
          crumb: "Checkout",
        },
        element: <Home />,
      },
      {
        path: "/not-found",
        element: <NotFound />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
    ],
  },
])

const AppRoutes = () => {
  return <RouterProvider router={router} />
}

export default AppRoutes
